import React from "react";
import { graphql } from "gatsby";
import { PageLayout } from "../components/layout/PageLayout";
import { BLOCKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";
import "./project-post.css";

const ProjectLayout = ({ data }) => {
  const project = data.contentfulProjects;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target;

        return (
          <div className="flex w-full sm:w-4/5 my-2 mx-auto">
            <GatsbyImage
              image={getImage(gatsbyImage)}
              alt={description ? description : ""}
            ></GatsbyImage>
          </div>
        );
      },
    },
  };

  return (
    <>
      <PageLayout>
        <Link to="/projects" className="link">
          👈 Back to projects
        </Link>
        <GatsbyImage
          className="project-cover-image"
          objectPosition="center"
          image={project.coverImage.gatsbyImage}
          alt={project.title}
        />
        <section id="project">
          <div id="project-header" className="project-header">
            <h1 className="project-header--title">{project.title}</h1>
            <div className="project-stats">
              <div className="project-stats--item">
                <span className="project-stats--title">Role:</span>{" "}
                <span className="project-stats--text">{project.role}</span>
              </div>
              <div className="project-stats--item">
                <span className="project-stats--title">Year:</span>{" "}
                <span className="project-stats--text">
                  {project.year.slice(0, 4)}
                </span>
              </div>
              {project.platforms ? (
                <div className="project-stats--item">
                  <span className="project-stats--title">Platforms:</span>
                  <ul className="project-stats--text">
                    {project.platforms?.map((value, i) => (
                      <li key={i}>{value} </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div id="project-summary" className="project-summary">
            <h2>Summary</h2>
            {project.summary?.raw && renderRichText(project.summary, options)}
          </div>
          <div id="project-description">
            {project.description?.raw &&
              renderRichText(project.description, options)}
          </div>
        </section>
      </PageLayout>
    </>
  );
};

export default ProjectLayout;

export const pageQuery = graphql`
  query ProjectById($id: String) {
    contentfulProjects(id: { eq: $id }) {
      id
      title
      client
      role
      platforms
      year
      summary {
        raw
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
          }
          gatsbyImage(width: 800)
          __typename
        }
      }
      coverImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 640, width: 1200) {
          src
        }
      }
      updatedAt
    }
  }
`;
